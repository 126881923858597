@import 'variable';

body{
    .integrations-section{
        .integrations-container{
            padding: 0;
            .api-keys-listing-wrapper{
                padding: 20px;
                border: 1px solid #C0CCD4;
                .title-blk{
                    margin-bottom: 24px;
                    position: relative;
                    .primary-btn{
                        line-height: 14px;
                        position: absolute;
                        right: 0;
                        bottom: -5px;
                    }
                    .heading2{
                        img, span{
                            vertical-align: middle;
                        }
                        img{
                            margin-right: 5px;
                        }
                    }
                }
                .api-keys-listing-table{
                    tbody tr td {
                        height: 40px;
                        padding-top: 0;
                        padding-bottom: 0;
                    }
                    .api-key-wrapper, .secret-wrapper, .actions-wrapper {
                        display: flex;
                        align-items: center;
                        gap: 5px;
                        margin-left: -4px;
                        .img-wrapper {
                            display: flex;
                            width: 26px;
                            height: 26px;
                            align-items: center;
                            justify-content: center;
                            cursor: pointer;
                            border-radius: 50%;
                            transition: all .4s ease-in;
                            &:hover {
                                background: rgba(0,145,179,.1);
                            }
                        }
                    }
                }
            }
        }
    }
    .api-action-dialog{
        .content-wrapper{
            min-width: 430px;
            padding: 60px 110px;
            box-sizing: border-box;
            .content{
                .paragraph{
                    span{
                        font-weight: $boldFont;
                    }
                }
            }
            .footer{
                text-align: center;
                padding-top: 30px;
                li{
                    &.form-group-field{
                      margin-bottom: 0;
                      input[type="submit"]{
                        padding: 8px 18px;
                      }
                    }
                    &:first-child{
                      margin-right: 25px;
                    }
                }
            }
        }
        .MuiDialog-paper{
            max-width: 400px;
        }
        &.add-new-api-key{
            .MuiPaper-root{
                max-width: 440px;
            }
            .content-wrapper{
                padding: 30px;
                // min-width: 490px;
                form{
                    .title{
                        margin-bottom: 16px;
                        img, span{
                            vertical-align: middle;
                        }
                        img{
                            margin-right: 5px;
                        }
                    }
                    .copy-btn-wrapper{
                        position: relative;
                        .MuiInputBase-root{
                            padding-right: 80px;
                        }
                        .copy-btn{
                            position: absolute;
                            right: 10px;
                            top: 10px;
                            padding: 4.5px 20px;
                            background: transparent;
                        }
                    }
                    .footer{
                        padding-top: 25px;
                        li{
                            .secondary-btn{
                                padding: 7px 21px;
                            }
                            &:last-child{
                                margin-right: 0;
                            }
                            &.form-group-field{
                                input[type="submit"]{
                                 width: 80px;
                                }
                            }
                        }
                        // li{}
                        // margin-bottom: 0;
                        // padding-top: 15px;
                        // input[type="submit"]{
                        //     max-width: 80px;
                        // }
                    }
                }
            }
        }
    }

}