@import 'variable';

body{
    .campaign-detail-section{
        .campaign-detail-container{
            padding: 0;
            .top-block{
                .back-btn{
                    a{
                        img, span{
                            vertical-align: middle;
                        }
                        img{
                            margin-right: 3px;
                        }
                        span{
                            color: #C4C8C9;
                        }
                    }
                }
                .basic-details-wrapper{
                    .campaignId{
                        margin-bottom: 30px;
                        font-size: 22px;
                    }
                    .basic-details-list{
                        text-align: center;
                        margin-bottom: 30px;
                        display: flex;
                        flex-direction: row;
                        > .w-50 {
                            width: 50%;
                        }
                        .column {
                            height: 100%;
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            gap: 11px;
                            margin: 0 auto;
                            width:fit-content;
                        }
                        .border-left{
                            border-left: 1px solid #EDEEEF;
                        }
                        li{
                            text-align: left;
                            .heading2{
                                span{
                                    font-weight: $regularFont;
                                    margin-right: 5px;
                                    &.primary-color{
                                        color: $color_0091B3;
                                        font-weight: $boldFont;
                                    }
                                    &.error-color{
                                        color: $color_red;
                                        font-weight: $boldFont;
                                    }
                                }
                                font-weight: $boldFont;

                            }
                            .campaign-usecase-wrapper{
                                .heading2{
                                    span{
                                        &.val{
                                            cursor: pointer;
                                            font-weight: $boldFont;
                                        }
                                    }
                                    .dropdown-arrow{
                                        vertical-align: middle;
                                        @include transitionForAll($time: 0.4s);
                                        color:   $color_primary_text;
                                        &.open{
                                            transform: rotate(180deg);
                                        }
                                    }
                                }
                            }
                            &:last-child{
                                margin: 0;
                            }
                        }
                    }
                }
            }
            .details-wrapper{
                padding: 12px 20px;
                border: 1px solid $color_border;
                margin-bottom: 30px;
                .paragraph{
                    word-break: break-word;
                }
                .title{
                    svg {
                        font-size: 20px;
                    }
                    padding-bottom: 15px;
                    margin-bottom: 15px;
                    border-bottom: 1px solid $color_border;
                    h3{
                        img, span{
                            vertical-align: middle;
                        }
                        img{
                            margin-right: 5px;
                            width: 18px;
                        }
                    }
                    &.border-none {
                        border-bottom: none;
                        padding-bottom: 0;
                        margin-bottom: 0;
                    }
                }
                &.brand{

                }
                &.attributes{
                    .campaign-attribute-wrapper{
                        background: $color_success_background;
                        margin-bottom: 10px;
                        .view-container{
                            max-height: 40px;
                            min-height: 40px;
                            padding: 0 0 0 20px;
                        }
                        p{
                            span{
                                font-weight: $mediumFont;
                                vertical-align: middle;
                            }
                            img{
                                vertical-align: middle;
                                margin-left: 5px;
                                height: 10px;
                                margin-bottom: 2px;
                            }
                        }
                        .value-wrapper{
                            margin-top: -2px;
                            padding-left: 15px;
                            .view-item{
                                margin-right: 15px;
                                img{
                                    position: relative;
                                    top: 3px;
                                    margin-right: 5px;
                                }
                                span{
                                    font-weight: $boldFont;
                                    color: #C0CCD4;
                                }
                            }
                        }
                        &:last-child{
                            margin-bottom: 0;
                        }
                    }
                }
                &.sample-messages{
                    .sample-message-item-wrapper{
                        margin-bottom: 15px;
                        position: relative;
                        .count{
                            position: absolute;
                            padding: 1px 4.5px 1px;
                            background: $color_primary_text;
                            border-radius: 3px;
                            @include elementStyles(
                                $color: #fff,
                                $weight: $boldFont,
                            )
                        }
                        p{
                            word-break: break-all;
                            margin-left: 30px;
                        }
                        &:last-child{
                            margin: 0;
                        }
                    }
                    .title{
                        svg {
                            font-size: 20px;
                        }
                    }
                }
                &.carrier-status{
                    margin-top: 20px;
                    &.blur-bg{
                        position: relative;
                        background: rgba(14, 31, 43, 0.65);
                        min-height: 300px;
                        .custom-scrollbar{
                            opacity: 0.2;
                        }
                    }
                    .custom-scrollbar{
                        padding-right: 8px;
                        // max-height: 330px;
                        margin-right: -8px;
                        .campaign-operations{
                            .network-item-wrapper{
                                margin-bottom: 10px;
                                &:last-child{
                                    margin: 0;
                                }
                                .name{
                                    height: 80px;
                                    background: $color_success;
                                    p{
                                        color: #fff;
                                        font-weight: $mediumFont;
                                        text-align: center;
                                        width: 100%;
                                    }
                                }
                                .label{
                                    text-align: center;
                                    padding: 11px 20px;
                                    background: $color_success_background;
                                    height: 40px;
                                    box-sizing: border-box;
                                    .supportText{
                                        font-weight: $mediumFont;
                                        img, span{
                                            vertical-align: middle;
                                        }
                                        img{
                                            margin-right: 5px;
                                        }
                                    }
                                    &.sharingStatus{
                                        padding-left: 0;
                                        text-align: left;
                                    }
                                }
                                .value{
                                    text-align: center;
                                    height: 40px;
                                    padding: 11px 0;
                                    box-sizing: border-box;
                                    background: rgba(243, 244, 244, 0.75);
                                    p{
                                        img, span{
                                            vertical-align: middle;
                                        }
                                        img{
                                            margin-right: 5px;
                                        }
                                        span{
                                            text-transform: capitalize;
                                        }
                                    }
                                    &.sharingStatus{
                                        p{
                                            text-align: left;
                                        }
                                    }
                                }
                                .elect-secondary-dca{
                                    padding: 8px 0 9px;
                                    height: 40px;
                                    box-sizing:border-box;
                                    background: rgba(243, 244, 244, 0.75);
                                    .secondary-dca-select{
                                        .secondary-dca-select-button{
                                            position: relative;
                                            display: flex;
                                            flex-direction: row;
                                            align-items: center;
                                            gap: 6px;
                                            max-width: 100%;
                                            padding: 5px 7px;
                                            margin-top: 1px;
                                            background: #68737A;
                                            cursor: pointer;
                                            box-sizing: border-box;
                                            span{
                                                font-family: Roboto, sans-serif;
                                                font-weight: 400;
                                                font-size: 12px;
                                                line-height: 14px;
                                                color: #fff;
                                            }
                                            .elect-icon {
                                                width: 11px;
                                                height: 12px;
                                                object-fit: contain;
                                            }
                                            .dropdown-arrow{
                                                width: 14px;
                                                height: 8px;
                                                object-fit: contain;
                                            }
                                        }
                                    }
                                    .share-now-button{
                                        display: flex;
                                        align-items: center;
                                        justify-content: center;
                                        .primary-btn{
                                            padding: 6px 7px 5px;
                                            display: inline-block;
                                            background: #00698F;
                                        }
                                    }
                                }
                            }
                        }
                    }
                    .title{
                        border-bottom: none;
                        padding-bottom: 0;
                    }
                    .cnp-nomination-wrapper{
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%,-50%);
                        .content-wrapper{
                            background: #fff;
                            padding: 30px 40px;
                            max-width: 430px;
                            box-sizing: border-box;
                            .paragraph{
                                span{
                                    font-weight: $boldFont;
                                }
                            }
                            .footer{
                                margin-top: 30px;
                                text-align: center;
                                li{
                                    margin-right: 20px;
                                    width: 80px;
                                    &:last-child{
                                        margin-right: 0;
                                    }
                                    p{
                                        a{
                                            display: inline-block;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                &.campaign-events {
                    .tableWrapper{
                        table{
                            tbody{
                                .empty-row{
                                    height: 240px !important;
                                }
                                tr{
                                    &:nth-child(odd) {
                                        .MuiExpansionPanel-root{
                                            background: rgba(243, 244, 244, 0);
                                        }
                                    }
                                    &:nth-child(even) {
                                        .MuiExpansionPanel-root{
                                            background: rgba(243, 244, 244, 0);
                                        }
                                    }
                                    td{
                                        cursor: text;
                                        height: 40px;
                                        padding: 0 10px 0 16px;
                                    }
                                    .event-type-wrapper{
                                        position: relative;
                                        img{
                                            position: absolute;
                                            width: 18px;
                                        }
                                        p{
                                            font-weight: 500;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                .brandSuspended{
                    margin-bottom: -10px;
                    .MuiGrid-item:nth-child(3n+2),
                    .MuiGrid-item:nth-child(3n+3) {
                        padding-left: 35px;
                        padding-bottom: 0px;
                        margin-bottom: 12px;
                        border-left: 1px solid $color_light_grey;
                    }
                }
            }
        }
    }
    .tableWrapper table.phone-number-table {
        tbody tr {
            cursor: default;
            &:hover {
                background: initial;
                box-shadow: none;
                transform: none;
                transition: none;
            }
            &:nth-child(even):hover {
                background: rgba(243, 244, 244, 0.5)
            }
        }
    }
    .secondary-dca-menu{
        .MuiMenu-paper{
            border-radius: 0;
            box-shadow: none;
            margin-top: 30px !important;
            .MuiMenu-list{
                background: #68737A;
                padding: 0;
                outline: none;
                min-width: 200px;
                box-sizing: border-box;
                max-height: 120px;
                li{
                    cursor: pointer;
                    &:hover{
                        background: #49535D;
                    }
                }
            }
        }
    }
    .campaign-subusecases-menu{
        .MuiMenu-paper{
            margin-top: 28px !important;
            border-radius: 0;
            box-shadow: none;
            .MuiMenu-list{
                background: $color_primary_text;
                padding: 0;
                li{
                    cursor: text;
                    min-width: 130px;
                    &:nth-child(even){
                        background:  rgba(255, 255, 255, 0.5);
                    }
                }
            }
        }
    }
    .elect-cnp-alert-wrapper {
        .MuiSnackbar-root {
            top: 122px;
            right: 0;
            .MuiPaper-root {
                width: 300px;
                padding: 12px 12px 12px 16px;
                border-radius: 15px 0 0 15px;
                background: #34777B;
                box-sizing: border-box;
                .MuiSnackbarContent-message{
                    padding: 0;
                    width: 100%;
                }
            }
        }
        .alert-content-wrapper {
            position: relative;
            display: flex;
            flex-direction: row;
            align-items: center;
            .alert-icon {
                margin-right: 8px;
                transform: rotate(15deg);
                font-size: 22px;
            }
            .close-icon {
                position: absolute;
                top: 0;
                right: 0;
                cursor: pointer;
                opacity: 0.5;
                font-size: 20px;
                color: white;
            }
            .alert {
                h3 {
                    margin-bottom: 5px;
                    font-size: 18px;
                    line-height: 21px;
                    font-weight: 700;
                }
                p {
                    font-size: $font12;
                }
                h3, p {
                    color: $color_white;
                }
            }
        }
    }
}