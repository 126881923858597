@import 'variable';

body{
    .campaigns-by-csps-section{
        .campaigns-by-csps-container{
            padding: 0;
            .filters {
                .MuiAutocomplete-root {
                    width: auto;
                }
                margin-bottom: 12px;
            }
            .page-actions {
                margin-bottom: 12px;
            }
            .tableWrapper{
                table{
                    tbody{
                        tr{
                            td{
                                .campaign-status-wrapper{
                                    justify-content: space-between;
                                    h3{
                                        span, img{
                                            vertical-align: middle;
                                        }
                                        span{
                                            text-transform: capitalize;
                                        }
                                        img{
                                            margin-right: 5px;
                                        }
                                    }
                                    .action-buttons-wrapper{
                                        ul{
                                            li{
                                                margin-left: 10px;
                                                &:first-child{
                                                    margin-left: 15px;
                                                }
                                                p{
                                                    a{
                                                        padding: 6px 11px;      
                                                    }
                                                }
                                                .primary-btn{
                                                    background: $color_success;
                                                }
                                                .secondary-btn{
                                                    padding: 5px 12px;   
                                                    background: $color_text;   
                                                    border: 1px solid $color_text;
                                                    color: #fff;
                                                }
                                            }
                                        }
                                        &.disabled{
                                            
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .campaigns-action-dialog{
        .content-wrapper{
            margin-bottom: 30px;
            .title{
                margin-bottom: 20px;
                font-size: 14px;
                line-height: 16px;
                img{
                    position: relative;
                    right: 5px;
                    top: 2px;
                }
            }
            p{
                color: $color_title;
                span{
                    font-weight: $boldFont;
                }
            }
            .form-group-field{
                text-align: left;
            }
        }
        .footer{
            li{
                p{
                    a{
                      display: inline-block;
                      &.primary-btn{
                          padding: 8px 18px;
                      }  
                      &.secondary-btn{
                          padding: 7px 21px;
                      }
                    }
                }
                &:first-child{
                    margin-right: 20px;
                }
            }
        }
    }
}