$font_Roboto: 'Roboto', sans-serif;

$lightFont: 300;
$regularFont: 400;
$mediumFont: 500;
$semiBoldFont: 600;
$boldFont: 700;

$font12: 12px;
$font14: 14px;
$font16: 16px;
$font18: 18px;
$font24: 24px;
$font32: 32px;
$font36: 36px;
$font48: 48px;

$color_black: #000000;
$color_white: #ffffff;
$color_primary_text : #FE772D;
$color_success: #00AC9D;
$color_text: #68737A;
$color_primary_background: rgba(254, 119, 45, 0.2);
$color_success_background: rgba(0, 172, 157, 0.1);
$color_title: #49535D;
$color_grey: #EDE8E8;
$color_danger: #D41C54;
$color_04ADB0: #04ADB0;
$color_menu: #00698F;
$color_menu_background: rgba(255, 255, 255, 0.5);
$color_0091B3 : #0091B3;
$color_border: #C0CCD4;
$color_red: #D41C54;
$color_footer: #4F575C;
$color_light_grey: #C0CCD4;
$color_td_text: #49535D;

.primary-color{
    color: $color_0091B3;
}
.error-color{
    color: $color_red;
}

@mixin elementStyles($size: null, $color: null,  $lineHeight: null, $weight: null, $fontFamily: null, $margin: null, $padding: null){
    @if $size != null {
        font-size: $size;
    }
    @if $lineHeight != null {
        line-height: $lineHeight;
    }
    @if $weight != null {
        font-weight: $weight;
    }
    @if $color != null {
        color: $color;
    }
    @if $fontFamily != null {
        font-family: $fontFamily;
    }
    @if $margin != null {
        margin: $margin;
    }
    @if $padding != null {
        padding: $padding;
    }
}

@mixin transitionForAll($time: 0.3s, $transProp: ease) {
    -webkit-transition: all $time $transProp;
    -moz-transition: all $time $transProp;
    -o-transition: all $time $transProp;
    transition: all $time $transProp;
    transition: all $time $transProp;
}