@import 'variable';

body{
    @include elementStyles(
        $fontFamily: 'Roboto',
        $lineHeight: 14px,
        $weight: $regularFont,
        $size:$font12,
        $color: $color_text,
    );
    *{
      padding: 0;
      margin: 0;
    }
    padding: 0;
    margin: 0;
    .text-center{
        text-align: center
    }
    .list-unstyled{
        list-style: none;
        padding: 0;
        margin: 0;
    }
    .list-inline{
        list-style: none;
        li{
            display: inline-block;
        }
    }
    .flex-centered{
        display: flex;
        display: -webkit-flex;
        flex-flow: wrap;
        -webkit-flex-flow: wrap;
        align-items: center;
        &:before,&:after{
          width: 0;
        }
    }

    .flex-not-centered{
        display: flex;
        display: -webkit-flex;
        flex-flow: wrap;
        -webkit-flex-flow: wrap;
        &:before,&:after{
            width: 0;
        }
    }
    .background-prop{
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
    }
    .flex-item{

        /* this */
        flex: 1 100px;

        /* is the same as */
        flex-grow: 1;
        flex-basis: 100px;

        /* and it leaves the flex-shrink property alone, which would be */
        flex-shrink: inherit; /* defaults to 1 */

    }
    .form-group-field{
        position: relative;
        margin-bottom: 15px;
        .MuiFormControl-marginNormal{
          margin: 0;
        }
        .MuiOutlinedInput-input{
          padding: 10px !important;
        }
        .MuiInputBase-root.Mui-disabled{
          opacity: 0.4;
          input{
            color: #000;
          }
        }
        .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline{
          border-color: $color_success;
        }
        .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline{
          border-color: #f44336;
        }
        .MuiInput-underline:after{
          border-bottom: 2px solid $color_success ;
        }
        .MuiOutlinedInput-multiline{
          padding: 0px;
        }
        .css-2b097c-container{
          width: 100%;
          .makeStyles-input-179{
            margin-top: -6px;
          }
        }
        .MuiFormControl-root{
          width: 100%;
          background: rgba(192, 204, 212, 0.15);
        }
        .MuiSelect-select{
          padding-top:5px;
          padding-left: 5px;
        }
        .field-wrapper{
          display: flex
        }
        .MuiInputBase-input{
          font-family: 'Roboto';
          font-size: 12px;
          color: $color_text;
          font-weight: $mediumFont;
          &:hover{
            outline: none;
          }
          &:focus{
            border-radius: 4px;
          }
        }
        .MuiFormLabel-root{
          font-size: 12px;
          font-family: "Roboto";
        }
        .MuiInputLabel-formControl{
          top: 3px;
          left: 5px;
        }
        .MuiInputAdornment-positionEnd{
          padding-right: 10px
        }
        .MuiFormLabel-root.Mui-focused{
          color: $color_success;
        }
        .MuiFormLabel-root.Mui-error {
          color: #f44336;
        }
        .MuiOutlinedInput-adornedEnd{
          padding-right: 3px !important;
        }
        .dropdown-selection{
           width: 100%;
           .css-yk16xz-control, .css-1pahdxg-control{
            background: transparent !important;
          }
          >div{
            border-radius: 3px;
            width: 100%;
            cursor: pointer;
            font-family: 'Roboto' !important;
            font-size: 12px !important;
            padding: 1px 0 ;
            &:hover{
              outline: none;
            }
          }&:focus, &:active >div{
              border-radius: 4px;
              outline: none;
              &:hover{
                border: none;
                outline: none
              }
          }
          &.field-error{
            >div{
              // border: 1px solid #e12525;
            }
          }
          .css-26l3qy-menu {
            *{
              cursor: pointer !important;
            }
          }
        }
        .MuiTextField-root{
          background: rgba(192, 204, 212, 0.15);
        }
        input[type="text"], input[type="email"], input[type="password"], input[type="number"], input[type="url"], input[type="tel"],input[type="textarea"]{
          width: 100%;
          display: block;
          font-size: 12px;
          color: $color_text;
          font-weight: $mediumFont;
          font-family: 'Roboto';
          outline: none;
          padding: 6px 5px 8px !important;
          &:focus, &:hover, &:active{
            outline: none;
          }
          &.field-error{
            border: 1px solid #e12525;
            &:focus{
              outline: none;
              border: 1px solid #e12525;
            }
          }
        }
        textarea{
          padding-left: 5px;
        }
        .MuiAutocomplete-inputRoot{
          .MuiAutocomplete-input{
            padding-bottom: 7px !important;
          }
        }
        .error-msg{
          // position: absolute;
          font-size: 11px;
          color: #f44336;
          font-family: 'Roboto';
          margin-top: 5px;
          font-weight: 400;
          text-align: left;
        }
        input[type="submit"]{
          // width: 100%;
          padding: 8px 22px;
          font-weight: $regularFont;
          background: $color_primary_text;
          font-family: $font_Roboto;
          box-shadow: none;
          outline: none;
          border: none;
          cursor: pointer;
          border-radius: 200px;
          font-size: $font12;
          color: #ffffff;
          &:disabled{
            pointer-events: none;
            opacity: 0.4;
            cursor: not-allowed;
          }
        }
    }
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active  {
        -webkit-box-shadow: 0 0 0 30px rgba(242,242,242,0.8) inset !important;
    }
    input:-internal-autofill-selected {
      background: none !important;
    }
    .MuiInput-underline:after{
      border-bottom: 2px solid $color_success !important;
    }
    .MuiInput-underline.Mui-error:after{
      border-bottom-color: #f44336 !important
    }
    .MuiSelect-select{
      background: transparent !important;
    }
    .MuiAutocomplete-root{
        width: 100%;
        font-family: 'Roboto';
    }
    .MuiAutocomplete-noOptions{
        font-family: 'Roboto';
        font-size: 12px;
        font-weight: 500;
    }
    .MuiAutocomplete-listbox{
        font-family: 'Roboto';
        font-size: 12px;
        font-weight: 400;
        color: #fff !important;
        max-height: 160px !important;
    }
    .MuiAutocomplete-option{
    // cursor: pointer !important;
    &:hover{
        // color: #ffff;
        // background-color: #67737A !important;

        }
    }
    .MuiAutocomplete-option[aria-selected="true"]{
        background-color: rgba(255, 255, 255, 0.5) !important;
        color: #fff;
        font-weight: $mediumFont;
    }

    .makeStyles-success{
        background-color: #005E70;
    }
    .MuiSnackbarContent-root{
        font-family: 'Roboto'
    }
    //dropdown-styling
   .MuiMenu-list {
    background: $color_success;
    max-height: 200px;
    overflow: auto;
    box-shadow: none;
  }
  .MuiMenuItem-root {
    font-family: 'Roboto' !important;
    font-size: 12px !important;
    color: #fff !important;
    font-weight: $regularFont !important;
  }
  .MuiListItem-root.Mui-selected, .MuiListItem-root.Mui-selected:hover{
    // background-color: $color_menu_background !important;
    color: #fff;
    font-weight: $mediumFont;
  }

  a {
    text-decoration: none;
    &:hover,
    &:active,
    &:focus,
    &:visited {
      text-decoration: none;
      cursor: pointer;
      outline: 0;
    }
  }
  .hidden {
    display: none !important;
  }
  .disabled {
    border: none;
    pointer-events: none;
    opacity: 0.5;
    cursor: not-allowed;
  }

  img {
    max-width: 100%;
  }
  .primary-btn, .secondary-btn{
    padding: 8px 22px;
    border-radius: 200px;
    font-family: 'Roboto';
    font-size: $font12;
    cursor: pointer;
    font-weight: $regularFont;
    background: $color_primary_text;
    color: #fff;
    &.disabled{
      opacity: 0.4;
      pointer-events: none;
      cursor: not-allowed;
    }
   }
  .secondary-btn{
    padding: 7px 22px ;
    background: #FFF;
    border: 1px solid $color_primary_text;
    color: $color_primary_text;
  }

  .heading1{
    @include elementStyles(
      $fontFamily: 'Roboto',
      $lineHeight: 21px,
      $weight: $mediumFont,
      $size: $font18,
      $color: $color_title,
    );
  }
  .heading2{
    @include elementStyles(
      $fontFamily: 'Roboto',
      $lineHeight: 19px,
      $weight: $mediumFont,
      $size: $font16,
      $color: $color_title,
    );
  }
  .heading3{
    @include elementStyles(
      $fontFamily: 'Roboto',
      $lineHeight: 16px,
      $weight: $mediumFont,
      $size: $font14,
      $color: $color_title,
    );
  }
  .paragraph{
    @include elementStyles(
      $fontFamily: 'Roboto',
      $lineHeight: 14px,
      $weight: $regularFont,
      $size:$font12,
      $color: $color_text,
    );
    &.medium{
      font-weight: $mediumFont,
    }
    &.semiBold{
      font-weight: $semiBoldFont,
    }
    &.primary{
      color: $color_primary_text,
    }
  }
  .supportText{
    @include elementStyles(
      $fontFamily: 'Roboto',
      $lineHeight: 14px,
      $weight: $regularFont,
      $size:$font12,
      $color: $color_success,
    );
  }
}