@import 'variable';

body{
    .settings-section{
        .settings-container{
            padding: 0;
            .title{
                margin-bottom: 30px;
                img, span{
                    vertical-align: middle;
                }
                img{
                    margin-right: 5px;
                }
            }
            .default-action-wrapper{
                padding: 20px;
                border: 1px solid $color_border;
                margin-bottom: 30px;
                .default-action{
                    padding: 8px 20px;
                    background: rgba(0, 172, 157, 0.15);
                    .supportText{
                        span{
                            margin-right: 5px;
                        }
                        img{
                            position: relative;
                            top: 1px;
                        }
                    }
                    .select-action-wrapper{
                        text-align: right;
                        .primary-btn{
                            padding: 5.5px 22px;
                        }
                    }
                }
            }
            .exceptional-csps-listing-wrapper{
                padding: 20px;
                border: 1px solid $color_border;
                .add-new-btn-wrapper{
                    margin-bottom: 16px;
                    p{
                        text-align: right;
                        a{
                            display: inline-block;
                        }
                    }
                }
                .tableWrapper {
                    tr {
                        height: 40px;
                    }
                    .empty-row {
                        height: 160px;
                    }
                }
            }
        }
    }
    .select-action-btn{
        cursor: pointer;
        text-transform: capitalize;
        text-align: left;
        padding: 5px 20px;
        width: 130px;
        box-sizing: border-box; 
        position: relative;
        background: rgba(104, 115, 122, 0.6);
        &.REVIEW{
            // background: $color_0091B3;
        }
        &.ACCEPT{
            // background: $color_success;
        }
        &.DECLINE{
            // background: $color_text;
        }
        span, .dropdown-arrow{
            vertical-align: middle;
        }
        span{
            // font-weight: $mediumFont;
            color: #fff;
        }
        .dropdown-arrow{
            fill: #fff;
            position: absolute;
            right: 2px;
            top: 0px;
        }
        margin-right: 30px;
    }
    .default-actions-menu{
        .MuiMenu-paper{
            margin-top: 30px !important;
            border-radius: 0;
            // box-shadow: none;
            .MuiMenu-list{
                // background: $color_primary_text;
                background: rgba(104, 115, 122, 0.6);
                padding: 0;
                li{
                    text-transform: capitalize;
                    cursor: text;
                    min-width: 130px;
                    padding-left: 20px;
                    &:nth-child(even){
                        // background:  rgba(255, 255, 255, 0.5);
                        background: rgba(104, 115, 122, 0.8);
                    }
                }
            }
        }
    }
    .add-new-exception-csp{
        form{
            .title{
                margin-bottom: 15px;
                img, span{
                    vertical-align: middle;
                }
                img{
                    margin-right: 5px;
                    height: 19px;
                }
            }
            .suggestion-field{
                position: relative;
                img{
                    position: absolute;
                    bottom: 0;
                    right: -12px;
                }
            }
            .select-automated-action{
                padding: 10px;
                background: rgba(0, 172, 157, 0.15);
                margin-bottom: 45px;
                ul{
                    li{
                        width: 50%;
                        .select-action-btn{
                            margin: 0 0 0 auto;
                        }
                    }
                }
            }
            .footer{
                li{
                    margin: 0;
                    a{
                        display: block;
                        &.secondary-btn{
                            padding: 7px 20.5px 7px 21px;
                        }
                    }
                    .primary-btn{
                        padding: 8px 27px;
                    }
                    &:first-child{
                        margin-right: 20px;
                    }
                }
            }
        }
    }
}