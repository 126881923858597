@import 'variable';
body{
    .events-listing-section{
        min-height: 70vh;
        .heading{
            margin-bottom: 15px;
            h3{
                font-size: 16px;
            }
            img{
                width: 28px;
                margin-right: 10px;
            }
        }
        .page-actions {
          margin-bottom: 12px;
        }
        .filters {
          margin-bottom: 12px;
          box-sizing: border-box;

          .MuiAutocomplete-root {
            width: auto;
          }
        }
        .tableWrapper{
          table{
            tbody{
              tr{
                .events-listing-table-link{
                  cursor: pointer;
                  &:hover{
                    color: $color_0091B3 !important;
                    font-weight: bold;
                  }
                  &:active{
                    color: $color_0091B3 !important;
                    font-weight: bold;
                  }
                }
              }
            }
          }
        }
        .tableWrapper {
          .event-type-wrapper {
            font-weight: 500;
          }
          td {
            height: 40px;
            padding: 0 10px 0 16px;
            &.link {
              cursor: pointer;
              &:hover {
                color: #00667D !important;
                font-weight: bold;
              }
              &:active {
                color: #00667D !important;
                font-weight: bold;
              }
            }
          }
        }
      }
}