@import 'variable';

body{
    .user-authentication-section{
        .user-form-wrapper{
            min-height: 85vh;
            height: 100%;
            
            &.auth-thanks{
              background-color: #fff;
              background-clip: border-box;
              overflow: hidden;
            }
        }
        .auth-user-form{
            padding: 20px 20px 25px;
            width: 420px;
            box-sizing: border-box;
            background-color: #ffffff;
            border: 1px solid $color_border;
            input[type="submit"]{
              max-width: 90px;
            }
            .form-title{
              margin-bottom: 20px;
              text-align: center;
              h3{
                img, span{
                  vertical-align: middle;
                }
              }
              img{
                width: 16px;
                height: 16px;
                margin-right: 5px;
              }
              h3{
                margin-bottom: 15px;
              }
              .paragraph{
                width: 80%;
                margin: 0 auto;
              }
            }
            &.reset-password, &.forgot-password{
              padding-bottom: 20px;
              .form-title{
                margin-bottom: 30px;
                img{
                  width: 20px;
                  height: 25px;
                }
              }
              input[type="submit"]{
                margin: 15px 0 0 0;
              }
            }
        }
        .account-link-option{
            margin: 30px 0 0 0;
            padding-top: 20px;
            border-top: 1px solid #C0CCD4;
            color: $color_text;
            font-size: 12px;
            a{
                @include elementStyles(
                $color: $color_primary_text,
                $weight: $boldFont,
                $size: $font12
                )
            }
            &.forgotpwd{
                text-align: right;
                margin-top: 20px;
                padding-top: 0px;
                border: none;
                margin-bottom: 30px;
                a{
                    color: $color_success;
                    font-weight: $regularFont;
                }
            }
        }
        .campaign-registry{
            text-align: center;
            .header-img{
              padding-top: 40px;
            }
            .link{
              margin-top: 40px;
            }
            .content-blk{
              width: 550px;
              box-sizing: border-box;
              background: $color_success_background;
              border: 1px solid $color_success;
              padding: 30px;
              img{
                width: 50px;
                margin-bottom: 12px;
              }
              h3{
                @include elementStyles(
                  $color: $color_success,
                  $margin: 0 0 5px 0
                );
              }
              .heading2{
                &.text{
                  width: 350px;
                  margin: 0 auto;
                  @include elementStyles(
                    $color: $color_success,
                    $weight: $regularFont
                  );
                }
              }
              a{
                display: inline-block;
              }
            }
        }
    }
    .customTooltipWrapper{
      padding: 20px;
      background: rgba(0, 172, 157, 0.25);
      margin: 0;
      box-shadow: none;
      border-radius: 8px;

      .bellIcon {
        position: absolute;
        right: -12px;
        top: -16px;
      }
      p {
        margin-bottom: 5px;
      }
      ul {
        li {
          padding: 4px 0;
          text-align: left;
          svg,
          span {
            vertical-align: middle;
          }
          svg {
            font-size: 16px;
            margin-right: 5px;
            // fill: $color_green;
            fill: #00d2ad;
            &.clearIcon {
              fill: $color_red;
            }
          }
        }
      }

      .list-suggestion {
        padding-left: 10px;
        li {
          margin-left: 4px;
        }
      }
      .secondaryText {
        color: #00AC9D;

        &.boldText {
          font-weight: $boldFont;
        }

        img {
          margin-right: 4px;
        }
        .red {
          color: $color_red;
        }
      }
    }
}