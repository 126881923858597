@import 'variable';

body{
    .dashboard-section{
        .dashboard-container{
            padding: 0;
            .block-wrapper{
                padding: 15px 20px;
                border: 1px solid $color_border;
                .title{
                    margin-bottom: 24px;
                    h3{
                        img, span{
                            vertical-align: middle;
                        }
                        img{
                            margin-right: 5px;
                        }
                    }
                }
                .dashboard-campaigns-listing-table{
                    width: 100%;
                    thead {
                        position: sticky;
                        top: 0;
                        height: 40px;
                        z-index: 9;
                    }
                    .empty-row {
                        height: 160px;
                    }
                }
                .campaigns-count-block{
                    background: $color_title;
                    padding: 32px 24px;
                    border-radius: 14px;
                    height: 100%;
                    box-sizing: border-box;
                    h3{
                        color: $color_white;
                        font-weight: $boldFont;
                        margin-bottom: 26px;
                        display: flex;
                        justify-content: center;
                        img{
                            margin-right: 5px;
                        }
                        span{
                            text-align: left;
                        }
                    }
                    .count{
                        @include elementStyles(
                            $fontFamily: 'Roboto',
                            $lineHeight: 16px,
                            $weight: $boldFont,
                            $size: $font18,
                            $color: $color_white,
                            $margin: 0 0 30px 0
                          );
                    }
                    .primary-btn{
                        background: $color_primary_text;
                        box-shadow: 0px 0px 10px #FE772D;
                        display: inline-block;
                    }
                    &.primaryDca{
                        .primary-btn{
                            background: $color_success;
                            box-shadow: 0px 0px 10px #00AC9D;
                        }
                    }
                }
                &.dashboard-sharedByCsps{
                    .title{
                        h3{
                            img{
                                position: relative;
                                bottom: 3px;
                            }
                        }
                    }
                }
            }
        }
    }
}