@import 'variable';
body, html {
  height: 100%;
}

body {
  .user-dropdown {
    .MuiPopover-paper {
      top: 62px !important;
      right: 30px !important;
      width: 150px;
      left: unset !important;
    }
    .MuiMenu-list {
      outline: none;
      background: #0e1f2b !important;
      padding: 8px;
    }
    .MuiMenuItem-root {
      justify-content: flex-start;
      width: 100%;
      opacity: 0.8;
      border-bottom: 0.5px solid $color_primary_text;
      @include elementStyles(
        $fontFamily: 'Roboto' !important,
        $weight: $regularFont !important,
        $size: 12px !important,
        $color: #ffffff !important
      );
      @include transitionForAll($time: 0.4s);
      &:hover {
        font-weight: $mediumFont !important;
        // @include transitionForAll($time: 0.4s, $transProp: 'ease-in');
        opacity: 1;
      }
      &:last-child {
        border-bottom: none;
      }
    }
    .MuiMenuItem-gutters {
      padding: 8px 10px;
    }
    .MuiMenu-list {
      background: 'unset';
    }
    .MuiListItem-button:hover {
      background-color: inherit !important;
    }
  }
  header {
    &.primaryHeader {
      position: fixed;
      top: 0;
      left: 200px;
      right: 0;
      box-shadow: 7.009px 7.009px 5.006px rgba(175, 194, 203, 0.251);
      background: $color_white;
      z-index: 1000;
      box-sizing: border-box;
      .primaryHeaderGrid {
        padding: 20px 40px 14px 40px;
        height: 60px;
      }
      .header-title {
        display: flex;
        justify-content: flex-start;
        align-items: flex-end;
        span.note {
          margin-left: 16px;
          font-size: 18px;
          color: gray;
          zoom: 0.5;
        }
      }
      .user-information {
        justify-content: flex-end;
        .user-avatar {
          position: relative;
          margin-right: 10px;
          .paragraph {
            color: $color_white;
            right: 14px;
            top: 4px;
            position: absolute;
            font-weight: $boldFont;
          }
        }
        .username {
          font-weight: $regularFont;
          cursor: pointer;
          span {
            font-weight: $boldFont;
          }
        }
      }
    }
  }
  .tableWrapper {
    table {
      tbody {
        tr {
          // for event table description expand / collapse
          .MuiExpansionPanel-root {
            background: transparent;
            .MuiExpansionPanelSummary-root {
              width: 100%;
              padding: 13px 0;
              min-height: 14px;
            }

            .MuiExpansionPanelSummary-root.Mui-expanded {
              word-break: break-all;
            }

            .MuiExpansionPanelSummary-content {
              color: $color_td_text;
              margin: 0;
              font-size: 12px;
              @include transitionForAll($time: 0.3s);
              word-break: break-all;

              &.Mui-expanded {
                word-break: break-word;
              }
            }

            .MuiExpansionPanelSummary-expandIcon {
              padding: 0 8px;
              margin-right: -8px;
            }
          }
        }
      }
    }
  }
  .pagination-wrapper {
    margin-top: 15px;
    width: 100%;
    text-align: center;
    .pagination {
      margin-top: 20px;
      ul {
        justify-content: center;
        li {
          button {
            margin: 0;
            border-radius: 0;
            @include elementStyles(
              $fontFamily: 'Roboto',
              $size: $font12,
              $weight: $regularFont,
              $color: $color_text,
              $lineHeight: 14px
            );
            background: #fff;
            border-color: transparent;
            .MuiSvgIcon-root {
              fill: $color_success;
              font-size: 25px;
            }
            &.Mui-selected {
              opacity: 1;
              border-radius: 50%;
              border: 1px solid $color_success;
              background: $color_success_background;
              color: $color_success;
              font-weight: 700;
            }
          }
        }
      }
    }
  }
  .filter-section-wrapper {
    .filter-button {
      position: relative;
      background: $color_primary_background;
      border-radius: 200px;
      padding: 9px 15px;
      cursor: pointer;
      // margin-bottom: 20px;
      float: right;
      @include transitionForAll($time: 0.4s);
      img {
        position: absolute;
        left: 15px;
        width: 14px;
        top: 8px;
      }
      p {
        color: $color_primary_text;
      }
    }
  }
  .filter-drawer {
    .MuiBackdrop-root {
      background-color: transparent;
    }
    .MuiDrawer-paper {
      top: 70px !important;
      max-height: calc(100vh - 25px);
      overflow-x: hidden;
      height: unset;
    }
    .filter-drawer-wrapper {
      max-width: 250px;
      .header-block {
        .title {
          padding: 13px 10px;
          background: $color_primary_background;
          // margin: -10px;
          position: relative;
          margin-bottom: 10px;
          img {
            position: absolute;
            left: 10px;
            width: 14px;
            top: 12px;
          }
          p {
            font-weight: 500;
            color: $color_primary_text;
          }
          .filter-close {
            cursor: pointer;
            color: $color_primary_text;
            opacity: 0.6;
            width: 16px;
            position: absolute;
            right: 11px;
            top: 9px;
          }
        }
      }
      .filter-item-wrapper {
        max-height: calc(100vh - 180px);
        overflow-y: auto;
        overflow-x: hidden;
        padding: 10px;
        padding-right: 0;
        .border {
          height: 1px;
          margin: 0 -10px;
          background-color: rgba(192, 204, 212, 0.5);
          margin-bottom: 10px;
        }
        .filter-item {
          padding-bottom: 10px;
          &.dropdown {
            padding-bottom: 5px;
          }
          // border-bottom: 1px solid rgba(192, 204, 212, 0.5);
          position: relative;
          &.form-group-field {
            position: relative;
            width: 100%;
            margin-bottom: 0px;
            .label {
              p {
                display: inline-block;
                font-weight: 500;
                color: $color_primary_text;
                margin-bottom: 3px;
              }
            }
          }
          .remove-icon {
            width: 12px;
            position: absolute;
            right: 5px;
            opacity: 0.6;
            top: 22px;
            cursor: pointer;
            @include transitionForAll($time: 0.3s);
            &:hover {
              transform: scale(1.1);
              opacity: 1;
            }
          }
          .dropdown-filter {
            max-height: 80px;
            &::-webkit-scrollbar-track {
              color: $color_primary_text;
              background: rgba(192, 204, 212, 0.3);
              border-radius: 100px;
            }
            &::-webkit-scrollbar-thumb {
              background: $color_primary_background;
              width: 5px;
              height: 30px;
            }
            .filter-list {
              li {
                .custom-checkbox {
                  padding: 0px 5px 2px 0;
                  &.Mui-checked {
                    padding: 0px 4px 2px 1px;
                    .MuiIconButton-label {
                      .MuiIcon-root {
                        img {
                          width: 16px;
                        }
                      }
                    }
                  }
                  &.Mui-disabled{
                    opacity: 0.5;
                    cursor: not-allowed;
                  }
                  .MuiIconButton-label {
                    .MuiIcon-root {
                      img {
                        width: 14px;
                      }
                    }
                  }
                }
              }
            }
          }
        }
        >div{
          &:last-child{
            .border{
              display: none;
            }
          }
        }
      }
      .filter-footer {
        padding: 10px;
        border-top: 1px solid rgba(192, 204, 212, 0.5);
        li {
          margin-right: 10px;
          &:last-child {
            margin: 0;
          }
          a {
            display: inline-block;
          }
          .secondary-btn {
            padding: 5px 22px;
            border: 1px solid $color_success;
            color: $color_success;
          }
          .primary-btn {
            padding: 6px 22px;
            background: $color_success;
          }
        }
      }
    }
  }
  .technical-error {
    position: absolute;
    top: 50%;
    left: 56%;
    transform: translate(-50%, -50%);
    margin-top: 30px;
    width: 70%;
    display: flex;
    flex-direction: column;
    align-items: center;
    // margin-top: 68px;
    img {
      margin-bottom: 30px;
    }
    h3 {
      margin-bottom: 20px;
      @include elementStyles(
        $fontFamily: 'Roboto',
        $size: 22px,
        $color: $color_title
      );
    }
    p {
      @include elementStyles(
        $fontFamily: 'Roboto',
        $size: $font14,
        $color: $color_text
      );
      margin-bottom: 35px;
    }
  }
  .page-layout {
    padding: 25px 30px;
  }
  .page-not-found-section {
    .page-not-found-container {
      min-height: 100vh;
      background: $color_success;
      .page-not-found-wrapper {
        position: relative;
      }
      img {
        width: 120px;
        position: absolute;
        left: -130px;
        top: -65px;
      }
      .content-blk {
        h3 {
          color: #fff;
          font-size: 72px;
          line-height: 72px;
        }
        p {
          color: #fff;
          max-width: 190px;
          text-align: center;
          margin-bottom: 60px;
        }
        a {
          display: inline-block;
        }
      }
    }
  }
  .mesh-loader {
    overflow: hidden;
    height: 100%;
    width: 100%;
    &.hoc {
      > div {
        margin-left: 0px;
      }
    }
  }
  .mesh-loader .circle {
    width: 25px;
    height: 25px;
    position: absolute;
    // background: linear-gradient(90deg, #054751 0%, #006FA0 18.3%, #0071A0 19.2%, #0291A8 32.5%, #03A5AE 43.6%, #04ADB0 51.1%, #F5C245 100%);
    background: $color_primary_text;
    border-radius: 50%;
    margin: -12.5px;
    -webkit-animation: mesh 3s ease-in-out infinite;
    animation: mesh 3s ease-in-out infinite -1.5s;
  }

  .mesh-loader > div .circle:last-child {
    -webkit-animation-delay: 0s;
    animation-delay: 0s;
  }

  .mesh-loader > div {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: 100px;
  }
  .mesh-loader > div:last-child {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
  }

  @-webkit-keyframes mesh {
    0% {
      -webkit-transform-origin: 50% -100%;
      transform-origin: 50% -100%;
      -webkit-transform: rotate(0);
      transform: rotate(0);
    }
    50% {
      -webkit-transform-origin: 50% -100%;
      transform-origin: 50% -100%;
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
    50.00001% {
      -webkit-transform-origin: 50% 200%;
      transform-origin: 50% 200%;
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform-origin: 50% 200%;
      transform-origin: 50% 200%;
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @keyframes mesh {
    0% {
      -webkit-transform-origin: 50% -100%;
      transform-origin: 50% -100%;
      -webkit-transform: rotate(0);
      transform: rotate(0);
    }
    50% {
      -webkit-transform-origin: 50% -100%;
      transform-origin: 50% -100%;
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
    50.00001% {
      -webkit-transform-origin: 50% 200%;
      transform-origin: 50% 200%;
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform-origin: 50% 200%;
      transform-origin: 50% 200%;
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }

  .filter-applied {
    background-color: #fff;
    margin-top: 16px;
    ul {
      flex-flow: row wrap;
      position: relative;
      padding-right: 116px;
      li {
        display: inline-block;
        margin: 0 16px 16px 0;
        padding: 0 10px 3px;
        border-radius: 200px;
        border: 1px solid $color_text;
        position: relative;
        padding-right: 28px;
        padding-top: 4px;
        @include elementStyles($color: $color_title, $weight: $mediumFont);
        &.disabledOption{
          padding: 3px 10px 3px;
        }
        & span {
          margin-left: 5px;
          color: $color_text;
          font-weight: $regularFont;
          word-break: break-word;
        }
        .filter-close {
          margin-left: 7px;
          cursor: pointer;
          @include transitionForAll($time: 0.3s);
          width: 14px;
          height: 14px;
          position: absolute;
          top: 50%;
          right: 8px;
          transform: translateY(-50%);
          -webkit-transform: translateY(-50%);
          -moz-transform: translateY(-50%);
          -ms-transform: translateY(-50%);
          -o-transform: translateY(-50%);
          &:hover{
            transform: scale(1.3) translateY(-50%);
            -webkit-transform: scale(1.3) translateY(-50%);
            -moz-transform: scale(1.3) translateY(-50%);
            -ms-transform: scale(1.3) translateY(-50%);
            -o-transform: scale(1.3) translateY(-50%);
          }
        }
        &.clear-filter {
          margin: 0;
          border: 1px solid $color_success;
          color: $color_success;
          padding: 3px 10px;
          cursor: pointer;
          font-size: 12px;
          position: absolute;
          top: 0;
          right: 0;
        }
      }
    }
  }
  .custom-scrollbar {
    overflow: auto;
    &::-webkit-scrollbar {
      width: 5px;
      cursor: pointer;
      height: 4px;
    }
    &::-webkit-scrollbar-track {
      color: #c4c8c9;
      background: rgba(243, 244, 244, 0.5);
      border-radius: 100px;
      width: 5px;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 100px;
      background: rgba(104, 115, 122, 0.3);
      height: 60px;
    }
  }
  .MuiExpansionPanel-root {
    box-shadow: none;
    .MuiExpansionPanelSummary-root {
      min-height: unset;
      font-family: 'Roboto';
      font-size: 14px;
      padding: 0;
      justify-content: flex-start;
      align-items: flex-start;
    }
  }
  .custom-tablecell {
    display: table-cell;
    vertical-align: inherit;
    .MuiCheckbox-root {
      padding: 12px 10px 11px;
    }
  }
  .default-dialog {
    .MuiDialog-paper {
      margin-left: 200px;
      .MuiDialogContent-root {
        padding: 30px;
      }
    }
  }
  .action-warning-dialog{
    .MuiDialog-paper {
      max-width: 400px;
    }
    .content-wrapper{
        margin-bottom: 30px;
        p{
            color: $color_title;
            span{
                font-weight: $boldFont;
            }
        }
    }
    .footer{
        li{
            a{
                display: block;
                &.primary-btn{
                    padding: 8px 18px;
                    min-width: 80.5px;
                    box-sizing: border-box;
                }
                &.secondary-btn{
                    padding: 7px 21px;
                }
            }
            &:first-child{
                margin-right: 20px;
            }
        }
    }
  }
  .auto-suggestions-dropdown {
    position: absolute;
    width: 100%;
    // max-width: 230px;
    max-height: 125px;
    z-index: 999;
    overflow: scroll;
    margin-top: 3px;
    border-radius: 4px;
    ul {
      background: #fff;
      padding: 0;
      li {
        // background: $color_menu;
        background: $color_success;
        word-break: break-word;
        color: #fff;
        font-size: 12px;
        // white-space: nowrap;
        // text-overflow: ellipsis;
        // overflow: auto;
        cursor: pointer;
        &:hover {
          background: rgba(0, 172, 157, 0.8);
        }
        &.noOptions {
          cursor: text;
        }
      }
    }
  }
  .custom-switch-wrapper{
    background: rgba(254, 119, 45, 0.15);
    border-radius: 100px;
    padding: 4px 5px;
    color: $color_primary_text;
    font-size: 12px;
    line-height: 14px;
    width: 50px;
    box-sizing: border-box;
    margin-right: 5px;
    cursor: pointer;
    @include transitionForAll($time: 0.4s);
    span{
        width: 12px;
        height: 12px;
        display: inline-block;
        border-radius: 50%;
        background: $color_primary_text;
        position: relative;
        top: 2px;
        margin-right: 7px;
    }
    &.yes{
        background: rgba(0, 172, 157, 0.15);
        color: $color_success;
        span{
            background: $color_success;
            margin-right: 0;
            margin-left: 5px;
        }
    }
    &.disabled{
        cursor: not-allowed;
        pointer-events: auto;
        opacity: 0.5;
    }
  }
  .no-access-section{
    .no-access-container{
      padding: 0;
      h3{
        margin-bottom: 16px;
      }
      p{
        margin-bottom: 32px;
      }
      a{
        display: inline-block;
      }
    }
  }
  .space-between{
    display: flex;
    justify-content: space-between;
  }

  // In order for text-trucate to work. The text container itself must have a max-width.
  // to incrases flexibilty of usage max-width is not included in this class.
  .text-trucate{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .flex {
    display: flex;
    // There is a CSS crash with portal-common. This is a temp fix.
    &.flex-colum{
      flex-direction: column;
    }
    &.flex-column{
      flex-direction: column;
    }
  }
  .h-100vh{
    height: 100vh;
  }
  .flex-1{
    flex: 1;
  }
  .footer-hoc-block{
    font-size: 10px;
    font-weight: 300;
    line-height: 12px;
    letter-spacing: 0em;
    text-align: center;
    color: $color_footer;
    padding: 25px 0;
    &.form{
      padding: 17px 30px 0px 240px;
    }
    &.csp{
      padding: 25px 30px 25px 30px
    }
    div {
      display: flex;
      justify-content: center;
      p {
        max-width: 802px;
        a {
          cursor: pointer;
          color: $color_footer;
          text-decoration: underline;
        }
      }
    }
  }
  .full-width {
    width: 100%;
  }
}
