@import 'variable';

body {
    .campaigns-by-primaryDcas-section {
        .campaigns-by-primaryDcas-container {            
            padding: 0;
            .tableWrapper {
                table {
                    tbody {
                        tr {
                            td {
                                .campaign-status-wrapper {
                                    justify-content: space-between;
                                    h3 {
                                        span, img {
                                            vertical-align: middle;
                                        }
                                        span {
                                            text-transform: capitalize;
                                        }
                                        img {
                                            margin-right: 5px;
                                        }
                                    }
                                    .action-buttons-wrapper {
                                        ul {
                                            li {
                                                margin-left: 10px;
                                                &:first-child {
                                                    margin-left: 15px;
                                                }
                                                p {
                                                    a {
                                                        padding: 6px 11px;
                                                    }
                                                }
                                                .primary-btn {
                                                    background: $color_success;
                                                }
                                                .secondary-btn {
                                                    padding: 5px 12px;
                                                    background: $color_text;
                                                    border: 1px solid $color_text;
                                                    color: #fff;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
            .MuiAutocomplete-root {
                width: auto;
            }
            .page-actions {
                margin-bottom: 12px;
            }
            .primaryDcas-campaigns {
                margin-bottom: 12px;
            }
        }
    }
    .campaigns-by-primaryDcas-action-dialog {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-end;
        width: 100%;
        min-height: 176px;
        box-sizing: border-box;
        .content-wrapper {
            width: 100%;
            margin-bottom: 30px;
            p {
                color: $color_title;
                span {
                    font-weight: $boldFont;
                }
            }
        }
        .footer {
            li {
                p {
                    a {
                        display: inline-block;
                        &.primary-btn {
                            padding: 8px 18px;
                        }
                        &.secondary-btn {
                            padding: 7px 21px;
                        }
                    }
                }
                &:first-child {
                    margin-right: 20px;
                }
            }
        }
    }
}